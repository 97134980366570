<template>
  <div>
    <div class="row" v-if="applications.length">
      <div class="col-md-12">
        <div class="row">
          <div class="col-xl-12 m-auto">
            <div
              class="card jobs mt-1"
              v-for="(application, index) in applications"
              :key="index"
            >
              <router-link :to="'/job/' + application.company_job_id">
                <div class="card-body" style="border-radius: 25px">
                  <b-row>
                    <b-col
                      cols="12"
                      lg="12"
                      class="d-flex justify-lg-content-between flex-column flex-lg-row"
                    >
                      <div class="order-1 order-lg-0">
                        <h5
                          class="card-title mb-1 font-weight-bolder font-weight-bold"
                        >
                          {{ application.job_title }}
                        </h5>
                        {{ application.company_name }}
                      </div>
                      <div
                        class="order-0 order-lg-1 ml-auto"
                        v-if="application.company_logo"
                      >
                        <img
                          :src="
                            application.company_logo == null
                              ? ''
                              : application.company_logo
                          "
                          height="70"
                        />
                      </div>
                    </b-col>
                    <b-col cols="12 mb-1" style="margin-top: 25px">
                      <h6 class="card-subtitle text-secondary">
                        <b>Vacancy</b>: {{ application.vacancy }}
                      </h6>
                    </b-col>
                    <b-col cols="12 mb-1">
                      <h6 class="card-subtitle text-secondary">
                        <b>Job type</b>: {{ application.commitment }}
                      </h6>
                    </b-col>
                    <b-col cols="12">
                      <div class="d-flex justify-content-between">
                        <div class="div">
                          <h6 class="card-subtitle text-secondary">
                            <b>Experience</b>:
                            {{ application.required_experience }} Years
                          </h6>
                          <h6
                            class="card-subtitle text-secondary"
                            v-if="application.company_district"
                            style="margin-top: 5px"
                          >
                            <b>Location</b>: {{ application.company_address }}
                          </h6>
                        </div>
                        <div
                          class="d-inline justify-content-center align-items-center"
                        >
                          <div class="mb-1 d-flex justify-content-end">
                            <h6 class="card-subtitle text-secondary">
                              <b-badge variant="primary">{{
                                application.status
                              }}</b-badge>
                            </h6>
                          </div>
                          <div>
                            <h6 class="card-subtitle text-secondary">
                              <b>Deadline:</b>
                              {{ application.deadline | date_format }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="text-center">
      <h4>No data found!</h4>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BCardTitle,
  BTabs,
  BTab,
  BCardText,
} from "bootstrap-vue";

export default {
  data() {
    return {
      applications: [],
    };
  },
  components: {
    BBadge,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BTabs,
    BTab,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults() {
      axios
        .get(`/candidate/my-applied-jobs`)
        .then((res) => {
          this.applications = res.data.applications;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
  },
};
</script>
<style scoped>
td p {
  margin-bottom: 0;
}

.jobs {
  transition: all linear 0.3s;
  cursor: pointer;
}

.jobs:hover {
  background-color: #f5f5f5;
}
</style>
